import clsx from 'clsx';
import { useMemo } from 'react';

import styles from './Icon.module.scss';
import { IconDefinitions, IconName } from './IconDefinitions';

interface IIconProps extends React.SVGProps<SVGSVGElement> {
  d?: string;
  iconName: IconName;
  // backgroundProps?: IIconBackground;
  addBackground?: boolean;
  backgroundClassName?: string;
  wrapperClassName?: string;
}

export type INamedIconProps = Omit<IIconProps, 'iconName'>;
export const Icon = ({
  addBackground,
  backgroundClassName,
  wrapperClassName,
  d,
  iconName,
  className,
  ...svgProps
}: IIconProps) => {
  const { viewBox, x, y, ...restSvgProps } = svgProps;
  const mainIconProps = useMemo(
    () => ({
      /** shrinks the inner icon if a background is present*/
      viewBox: viewBox || '0 0 24 24',
      /** centers the icon within the background */
      x: x || '0',
      /** centers the icon within the background */
      y: y || '0',
      ...restSvgProps,
    }),
    [restSvgProps, viewBox, x, y],
  );

  /**
   * background shape to add to an icon
   *
   * if `addBackground` comes in as a string, then it is the `d` property on a simple path
   * otherwise it defaults to a hexagon
   */
  const backgroundShape = d || 'M0,12L6.4,2l10.9,0L24,12l-6.8,10L6.4,22L0,12z';
  const backgroundClass = clsx(backgroundClassName ?? styles.iconBackground);
  const iconClassname = clsx(className, styles.iconMainIcon);
  const wrapperClass = clsx(wrapperClassName, styles.iconWrapper);

  return (
    <>
      {addBackground && (
        <svg className={wrapperClass} viewBox="0 0 24 24" x="0" y="0">
          <g className={backgroundClass}>
            <path d={backgroundShape} />
          </g>
          <svg
            className={iconClassname}
            id={`${iconName}-icon`}
            {...mainIconProps}
          >
            {IconDefinitions[iconName]}
          </svg>
        </svg>
      )}
      {!addBackground && (
        <svg
          className={iconClassname}
          id={`${iconName}-icon`}
          {...mainIconProps}
        >
          {IconDefinitions[iconName]}
        </svg>
      )}
    </>
  );
};
